import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCourses } from '../../redux/actions/course';
import toast from 'react-hot-toast';
import { addToPlaylist } from '../../redux/actions/profile';
import { loadUser } from '../../redux/actions/user';
import useScrollToTop from '../../useScrollToTop';
import {
  Box,
  Button,
  Image,
  Heading,
  Text,
  Container,
  Stack,
  VStack,
  HStack,
} from '@chakra-ui/react';
import './Home.css';

const Course = ({
  views,
  title,
  imageSrc,
  id,
  addToPlaylistHandler,
  creator,
  description,
  lectureCount,
  loading,
}) => {
  return (
    <VStack className="course" alignItems={['center', 'flex-start']}>
      <Image src={imageSrc} boxSize="60" objectFit="cover" alt={`${title} Image`} />
      <Heading
        textAlign={['center', 'left']}
        maxW="200px"
        size="sm"
        fontFamily="sans-serif"
        noOfLines={3}
      >
        {title}
      </Heading>
      <Text noOfLines={2}>{description}</Text>

      <HStack>
        <Text fontWeight="bold" textTransform="uppercase">
          Creator
        </Text>
        <Text fontFamily="body" textTransform="uppercase">
          {creator}
        </Text>
      </HStack>

      <Heading textAlign="center" size="xs">
        Lectures - {lectureCount}
      </Heading>

      <Heading size="xs">Views - {views}</Heading>

      <Stack direction={['column', 'row']} alignItems="center">
        <Link to={`/course/${id}`}>
          <Button colorScheme="yellow">Watch Now</Button>
        </Link>
        <Button
          isLoading={loading}
          variant="ghost"
          colorScheme="yellow"
          onClick={() => addToPlaylistHandler(id)}
        >
          Add to playlist
        </Button>
      </Stack>
    </VStack>
  );
};

const Home = () => {
  useScrollToTop();
  const [keyword, setKeyword] = useState('');
  const [category, setCategory] = useState('');
  const dispatch = useDispatch();

  const addToPlaylistHandler = useCallback(
    async (courseId) => {
      await dispatch(addToPlaylist(courseId));
      dispatch(loadUser());
    },
    [dispatch]
  );

  const { loading, courses, error, message } = useSelector((state) => state.course);

  useEffect(() => {
    dispatch(getAllCourses(category, keyword));

    if (error) {
      toast.error(error);
      dispatch({ type: 'clearError' });
    }

    if (message) {
      toast.success(message);
      dispatch({ type: 'clearMessage' });
    }
  }, [category, keyword, dispatch, error, message]);

  return (
    <Container minH="100vh" maxW="100vw" padding="0">
      <Box
        className="home"
        height="50%"
        width="100%"
        display="flex"
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent={{ base: 'center', md: 'flex-start' }}
        alignItems="center"
        textAlign={{ base: 'center', md: 'left' }}
      >
        <Box
          width={{ base: '100%', md: '50%' }}
          padding={{ base: '2rem', md: '2rem 2rem 1rem 2rem' }}
          mx={{ base: 'auto', md: '0' }}
        >
          <Heading size="xl" mb={4}>
            Welcome to CourseCraft
          </Heading>
          <Text fontSize={{ base: 'xl', md: 'lg' }} mb={6}>
            A comprehensive and accelerated course designed to equip you with
            essential coding techniques and knowledge in a fraction of the time
            it would traditionally take. Have you ever wished to learn the
            secrets of coding quickly and efficiently? Your journey to becoming
            a proficient coder starts here!
          </Text>
          <Link to="/courses">
            <Button size="lg" colorScheme="yellow">
              Explore Now
            </Button>
          </Link>
        </Box>
        <Box
          width={{ base: '100%', md: '50%' }}
          display={{ base: 'none', md: 'block' }}
          mx={{ base: 'auto', md: '0' }}
        >
          <Image
            src={
              'https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&amp;ixid=eyjhchbfawqiojeymdd9&amp;auto=format&amp;fit=crop&amp;w=1352&amp;q=80&quot;)'
            }
            alt="Course Image"
            className="img"
            width="100%"
            height="auto"
          />
        </Box>
      </Box>
      <Heading textAlign="center" size="xl" m={8}>
        Recommended Courses
      </Heading>

      <Stack
        direction={['column', 'row']}
        flexWrap="wrap"
        justifyContent={['flex-start', 'space-evenly']}
        alignItems={['center', 'flex-start']}
      >
        {courses && courses.slice(0, 3).map((item) => (
          <Course
            key={item._id}
            title={item.title}
            description={item.description}
            views={item.views}
            imageSrc={item.poster.url}
            id={item._id}
            creator={item.createdBy}
            lectureCount={item.numOfVideos}
            addToPlaylistHandler={addToPlaylistHandler}
            loading={loading}
          />
        ))}
      </Stack>
    </Container>
  );
};

export default Home;
