import { Box, Heading, HStack, Stack, VStack } from '@chakra-ui/react';
import React from 'react';
import {
  TiSocialYoutubeCircular,
  TiSocialInstagramCircular,
} from 'react-icons/ti';
import { DiGithub } from 'react-icons/di';
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Box padding={'4'} minH={'10vh'}>
      <Stack direction={['column', 'row']}>
        <VStack alignItems={['center', 'flex-start']} width="full">
          <Heading size={"sm"}>
            Copyright &copy; {currentYear} All Rights Reserved
          </Heading>
        </VStack>

        <HStack
          spacing={['2', '2']}
          justifyContent="center"
          fontSize="4xl"
        >
          <a href="https://youtube.com/" target={'blank'}>
            <TiSocialYoutubeCircular />
          </a>
          <a href="https://instagram.com/robot46052023" target={'blank'}>
            <TiSocialInstagramCircular />
          </a>
          <a href="https://github.com/SagarMajhi" target={'blank'}>
            <DiGithub />
          </a>
        </HStack>
      </Stack>
    </Box>
  );
};

export default Footer;
